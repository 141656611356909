* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

html body {
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  width: 100%;
  min-width: 100%;
}
